import { McgFhirExtensions } from '@utils/constants'
import StringUtils from '@utils/string-utils'

function values(object) {
  if (object != null) {
    return Object.values(object)
  }
  return null
}

function removeIncomplete(array) {
  return array?.filter((item) => !StringUtils.isNullOrWhitespace(item.Name))
}

function take(array, numberOfElements) {
  return array?.slice(0, numberOfElements)
}

function extractCodeDisplay(item) {
  return extractCodeDisplayFromConcept(item?.code)
}

function extractCodeDisplayFromConcept(concept) {
  return concept?.text ?? concept?.coding?.[0]?.display
}

function extractDiagnosticReportExtendedResults(diagnosticReport) {
  const extensions = diagnosticReport.extension?.find(
    (e) => e.url === McgFhirExtensions.DiagnosticReportExtensionUrl
  )
  return extensions?.valueCodeableConcept?.coding
}

function makeDoseDisplay(doseQuantity) {
  return doseQuantity
    ? `${doseQuantity?.value} ${doseQuantity?.unit ?? ''}`
    : null
}

export default {
  values,
  removeIncomplete,
  take,
  extractCodeDisplay,
  extractDiagnosticReportExtendedResults,
  extractCodeDisplayFromConcept,
  makeDoseDisplay,
}
