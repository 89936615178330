<template>
  <div v-if="numberPages > 1" :id="divId" class="pagination pagination-row">
    <button
      v-if="showNextPrevious"
      :id="divId + '-page-previous'"
      :class="['pagination-button', pageNumber <= 1 ? 'disabled' : '']"
      @click="changePageNumber(pageNumber - 1)"
    >
      Previous
    </button>
    <span v-for="(item, index) in numberPages" :key="index">
      <button
        :id="divId + '-page-' + index"
        :class="['pagination-button', pageNumber === index + 1 ? 'active' : '']"
        @click="changePageNumber(index + 1)"
      >
        {{ index + 1 }}
      </button>
    </span>
    <button
      v-if="showNextPrevious"
      :id="divId + '-page-next'"
      :class="[
        'pagination-button',
        pageNumber >= numberPages ? 'disabled' : '',
      ]"
      @click="changePageNumber(pageNumber + 1)"
    >
      Next
    </button>
  </div>
</template>

<script>
export default {
  name: 'PagingControl',
  props: {
    divId: {
      type: String,
      default: 'pagingControl',
    },
    startAtPage: {
      type: Number,
      default: 1,
    },
    numberPages: {
      type: Number,
      default: 0,
    },
    showNextPrevious: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageNumber: this.startAtPage,
    }
  },
  methods: {
    changePageNumber(newPageNumber) {
      if (newPageNumber >= 1 && newPageNumber <= this.numberPages) {
        this.pageNumber = newPageNumber
        this.$emit('changePageNumber', this.pageNumber, this.divId)
      }
    },
  },
}
</script>

<style lang="scss">
.pagination {
  margin: 0 10px;
  overflow: hidden;
  overflow-y: auto;

  .pagination-row {
    padding: 5px;
    font-weight: 500;
    background-color: #fff;

    .pagination-button {
      z-index: 1000;
      padding: 5px;
      margin: 4px;
      font-size: 1em;
      pointer-events: all;
      cursor: pointer;
      border-color: #dee2e6;
      border-style: solid;
      border-width: 1px 1px 0 1px;
      border-radius: 3px 3px 0 0;

      &.active {
        color: #fff;
        cursor: pointer;
        background: rgba(2, 88, 147, 1);
      }

      &.disabled {
        cursor: default;
      }
    }
  }
}
</style>
