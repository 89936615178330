<template>
  <div>
    <div class="mask">
      <button
        id="close-button"
        data-cy="close-help"
        class="submit button is-primary"
        @click="closeHelp"
      >
        Close
      </button>
      <iframe
        id="help-frame"
        data-cy="help-frame"
        class="popup"
        title="Help"
        :src="helpUrl"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Help',
  data() {
    return {
      helpUrl: process.env.VUE_APP_HELP_URL,
    }
  },
  methods: {
    closeHelp() {
      this.$emit('closeHelp')
    },
  },
}
</script>

<style lang="scss" scoped>
.mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  margin: 0%;
  background: #fafafa;
  opacity: 1;
}

.popup {
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 1000;
  display: flex;
  width: 92%;
  height: 92%;
  margin: 0% 3% 3% 3%;
  font-weight: bold;
  background: #efefef;
  border: 3px solid #e0e3e7;
  border-radius: 25px;
  opacity: 1;
}

#close-button {
  display: block;
  margin: 11px auto;
}
</style>
