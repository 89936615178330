<template>
  <div>
    <div
      v-if="pdfDocument !== undefined"
      :id="docId"
      class="pagination clinicalNotesPdf"
    >
      <keep-alive>
        <PagingControl
          :number-pages="pdfPages"
          :div-id="docId"
          :start-at-page="pdfDocument.currentPage"
          @changePageNumber="setPdfPage"
        />
      </keep-alive>
    </div>
    <div :id="'pdfContainer_' + docId" ref="pdfContainer" class="pdfContainer">
      <div
        :id="'pdfTextLayer_' + docId"
        ref="pdfTextLayer"
        class="pdfTextLayer"
      />
      <canvas
        :id="'pdfContentLayer_' + docId"
        ref="pdfContentLayer"
        class="pdfContentLayer"
      />
    </div>
  </div>
</template>

<script>
import PagingControl from '@components/paging-control.vue'
import * as pdfjs from 'pdfjs-dist/es5/build/pdf'
import * as pdfjsWorker from 'pdfjs-dist/es5/build/pdf.worker.entry'

export default {
  name: 'ClinicalNotePdf',
  components: {
    PagingControl,
  },
  props: {
    docId: {
      type: String,
      default: '',
    },
    contentData: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pdfDocument: {},
    }
  },
  computed: {
    pdfPages() {
      if (this.pdfDocument.pdf !== undefined)
        return this.pdfDocument.pdf.numPages
      return 0
    },
  },
  mounted() {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
    if (this.contentData !== '') {
      this.processPdf()
    }
  },
  methods: {
    processPdf() {
      if (this.pdfDocument.pdf === undefined) {
        pdfjs
          .getDocument({ data: this.contentData })
          .promise.then((pdf) => {
            this.pdfDocument = { pdf, currentPage: 1 }
            return pdf.getPage(this.pdfDocument.currentPage)
          })
          .then((page) => this.renderPage(page))
          .catch((error) => {
            this.$store.commit('log', `PDF display errors: ${error}`)
          })
      } else {
        this.setPdfPage(this.pdfDocument.currentPage)
      }
    },
    renderPage(page) {
      const { pdfContainer, pdfContentLayer: canvas, pdfTextLayer } = this.$refs
      const canvasContext = canvas.getContext('2d')
      const viewport = page.getViewport({ scale: 1 })
      const scale = (0.95 * pdfContainer.clientWidth) / viewport.width

      this.viewport = page.getViewport({ scale })

      pdfTextLayer.style.height = `${Math.floor(this.viewport.height)}px`
      pdfTextLayer.style.width = `${Math.floor(this.viewport.width)}px`
      canvas.height = this.viewport.height
      canvas.width = this.viewport.width

      page.render({
        canvasContext,
        viewport: this.viewport,
      })

      return page.getTextContent().then((textContent) => {
        pdfTextLayer.innerHTML = ''
        pdfjs.renderTextLayer({
          textContent,
          container: pdfTextLayer,
          viewport: this.viewport,
          textDivs: [],
        })
      })
    },
    setPdfPage(pageNumber) {
      this.pdfDocument.currentPage = pageNumber
      this.pdfDocument.pdf
        .getPage(this.pdfDocument.currentPage)
        .then((page) => {
          this.renderPage(page)
        })
    },
  },
}
</script>

<style lang="scss">
.pdfContainer {
  position: relative;
}
.pdfTextLayer {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  line-height: 1.3675;
  opacity: 0.2;

  > span {
    position: absolute;
    color: transparent;
    white-space: pre;
    cursor: text;
    transform-origin: 0% 0%;
  }
}
</style>
