export const ListLogicTypes = Object.freeze({
  Unknown: 0,
  All: 1,
  Some: 2,
  NotCovered: 3,
  Examples: 4,
  CRRU: 5,
})

export function parseListLogic(listLogicString, children) {
  let type = ListLogicTypes.Unknown
  let metThreshold
  if (listLogicString === 'all of the following') {
    type = ListLogicTypes.All
    metThreshold = children?.length // Should we error if there are no child nodes given?
  } else if (listLogicString === 'examples include') {
    type = ListLogicTypes.Examples
    metThreshold = 1
  } else if (!isNaN(parseInt(listLogicString, 10))) {
    type = ListLogicTypes.Some
    metThreshold = parseInt(listLogicString, 10)
  } else if (listLogicString === 'not covered for any of the following') {
    type = ListLogicTypes.NotCovered
  } else if (listLogicString === 'current role remains uncertain') {
    type = ListLogicTypes.CRRU
  }

  return {
    type,
    metThreshold,
  }
}

export function isMet(listLogicObject, metChildren) {
  if (
    listLogicObject.type === ListLogicTypes.All ||
    listLogicObject.type === ListLogicTypes.Some ||
    listLogicObject.type === ListLogicTypes.Examples
  ) {
    return metChildren >= listLogicObject.metThreshold
  }

  return false
}

export default {
  ListLogicTypes,
  parseListLogic,
  isMet,
}
