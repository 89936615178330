<template>
  <div class="bounded-scroll">
    <span v-for="item in documentedGuidelines" :key="item.code">
      <div
        v-if="hasSummary(item) || isNoGuidelineHsim(item.guidelineHsim)"
        id="codeList"
      >
        <div v-if="item.code !== noGuidelineHsim" class="greyBackground">
          <span id="code">
            {{ item.code }}
          </span>
          <span id="codeDescription">
            {{ item.codeDescription }}
          </span>
        </div>
        <div class="guidelineTitle">
          <span> {{ item.guidelineTitle }} </span>
        </div>
        <div
          v-if="!isNoGuidelineHsim(item.guidelineHsim)"
          class="displayIndications"
        >
          <TreePreviewIndication
            v-for="node in selectGuideline(item)"
            :key="node.uid"
            :node="node"
          ></TreePreviewIndication>
          <div class="copyRightText">
            <span>
              {{ copyRightText(item.contentEdition) }}
            </span>
          </div>
        </div>
      </div>
    </span>
    <div id="additionalNoteContent">
      <div class="additionalNoteTitle"> Additional Notes: </div>
      <div v-if="documentedNoGuidelineCount > 0" class="warningNote">
        <span id="codeDescription">
          Please add notes or any attachments to support the authorization
          request.
        </span>
      </div>
      <div id="additionalNote">
        <textarea
          v-model="episodeAdditionalNoteText"
          placeholder="Type your text here..."
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { NoGuidelinesApplyConstants } from '@utils/constants'
import TreePreviewIndication from '@/src/components/tree-preview-indication.vue'
import { guidelineCopyRightText } from '@utils/document-guideline-util'

export default {
  name: 'GuidelinePreviewText',

  components: {
    TreePreviewIndication,
  },
  props: {
    note: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      guidelineTitle: '',
      episodeAdditionalNoteText: this.episodeAdditionalNote || '',
      noGuidelineHsim: NoGuidelinesApplyConstants.Hsim,
    }
  },
  computed: {
    ...mapGetters('documentGuideline', [
      'episodeAdditionalNote',
      'documentedGuidelines',
      'hasSummary',
      'previewDocumentedGuidelines',
    ]),
    documentedNoGuidelineCount: function () {
      return this.documentedGuidelines.filter(
        (x) => x.guidelineHsim === this.noGuidelineHsim
      ).length
    },
  },
  watch: {
    episodeAdditionalNoteText(note) {
      this.updateEpisodeAdditionalNote({
        note: note,
      })
    },
  },
  methods: {
    ...mapActions('documentGuideline', ['updateEpisodeAdditionalNote']),
    isNoGuidelineHsim(hsim) {
      return hsim === this.noGuidelineHsim
    },
    selectGuideline(guideline) {
      return (
        this.previewDocumentedGuidelines.find(
          (x) =>
            x.code === guideline.code &&
            x.guidelineHsim === guideline.guidelineHsim
        )?.allIndicationPaths || []
      )
    },
    copyRightText(version) {
      return guidelineCopyRightText(version)
    },
  },
}
</script>

<style lang="scss">
.additionalNoteTitle {
  padding: 8px;
  font-size: 15px;
  font-weight: bold;
}
.greyBorder {
  border: solid 0 #dee2e6;
  border-radius: 3px;
}
.guidelineTitle {
  padding: 8px;
  font-size: 18px;
  font-weight: lighter;
}
.greyBackground {
  padding: 10px;
  background-color: #f4f5f5;
}
#codeDescription {
  font-size: 13px;
}
#code {
  font-size: 15px;
  font-weight: 900;
}
textarea {
  width: 100%;
  height: auto;
  padding: 5px;
  overflow: auto;
  resize: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.displayIndications {
  margin: 2px 2px;
  textarea {
    min-height: 250px;
    overflow: auto;
    background-color: #f4f5f5;
    border-color: white;
  }
}
#additionalNote {
  margin: 5px;
  margin-bottom: 0;
  textarea {
    min-height: 100px;
    border-color: #dee2e6;
    border-radius: 3px;
  }
}

#additionalNoteContent {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  margin-bottom: 12px;
  overflow: hidden;
  border: solid 1px #dee2e6;
  border-radius: 3px;
}
.warningNote {
  margin-left: 8px;
  font-size: 13px;
}
.copyRightText {
  margin: 10px;
  font-size: 14px;
}
</style>
