var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasData || !_vm.printMode)?_c('div',{staticClass:"card card-custom is-fullwidth"},[_c('a',{staticClass:"card-header no-select",class:{
      'card-disabled': !_vm.hasData && !_vm.alwaysEnabled,
      'card-inactive': (_vm.hasData || !_vm.alwaysEnabled) && !_vm.isExpanded,
    },attrs:{"id":("" + _vm.slugifiedTitle),"name":("" + _vm.slugifiedTitle),"data-target-id":("" + _vm.slugifiedTitle),"data-cy":(_vm.slugifiedTitle + "-card-header")},on:{"click":_vm.toggleExpand}},[_c('p',{staticClass:"card-header-title card-title",class:{ 'pdf-header': _vm.printMode }},[(_vm.iconPath)?_c('img',{attrs:{"src":_vm.iconPath,"alt":(_vm.title + "-icon")}}):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('span',{staticClass:"card-header-icon card-toggle",class:{
        'is-hidden': !_vm.printMode && !_vm.hasData && !_vm.alwaysEnabled,
      }},[(!_vm.printMode)?_c('span',[(_vm.isExpanded)?[_c('BaseIcon',{attrs:{"name":"angle-down"}})]:[_c('BaseIcon',{attrs:{"name":"angle-up"}})]],2):_vm._e()])]),_c('div',{staticClass:"card-content",class:{ 'is-hidden': !_vm.isExpanded && !_vm.printMode },attrs:{"data-cy":(_vm.slugifiedTitle + "-card-content")}},[_vm._t("content"),(_vm.hasData)?_c('div',{staticClass:"content"},[(!_vm.printMode)?_c('div',{staticClass:"filter"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterQuery),expression:"filterQuery"}],staticClass:"clinical-input-search",attrs:{"placeholder":("Search by " + _vm.resourceTitle + " name"),"type":"search"},domProps:{"value":(_vm.filterQuery)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filterQuery=$event.target.value}}}),(_vm.hasMatches && !_vm.printMode)?_c('label',{staticClass:"clinical-input-matches"},[_vm._v(" matches only "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.matchesOnly),expression:"matchesOnly"}],attrs:{"type":"checkbox","value":"true"},domProps:{"checked":Array.isArray(_vm.matchesOnly)?_vm._i(_vm.matchesOnly,"true")>-1:(_vm.matchesOnly)},on:{"change":function($event){var $$a=_vm.matchesOnly,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="true",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.matchesOnly=$$a.concat([$$v]))}else{$$i>-1&&(_vm.matchesOnly=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.matchesOnly=$$c}}}})]):_vm._e()]):_vm._e(),_c('b-table',{attrs:{"data":_vm.filteredData,"narrowed":"","striped":"","hoverable":"","mobile-cards":false,"detailed":_vm.hasDetails,"detail-key":"id","custom-detail-row":"","show-detail-icon":false,"opened-detailed":_vm.openedDetailRows,"default-sort":_vm.defaultSort,"sort-icon":"angle-up","row-class":function (row, index) { return ({
            'custom-selected': _vm.itemHasMatch(row),
            'hide-border': row.detailVisible,
            'pdf-body': _vm.printMode,
          }); }},on:{"sort":_vm.saveSort,"details-open":function (row) { return (row.detailVisible = true); },"details-close":function (row) { return (row.detailVisible = false); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" No results found ")]},proxy:true},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},[_vm._t("default")],2)],1):_vm._e()],2),_vm._t("footer")],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }