<template>
  <div id="guidelineTree">
    <GuidelineTitle @next-step="nextStep" />
    <div v-if="showGuidelineTree" class="tree-wrapper bounded-scroll">
      <ul class="tree">
        <TreeNode
          v-for="(treeNode, index) in tree"
          :id="treeNode.id"
          :key="'coveredIndications' + index"
          :item="treeNode"
          :tree-root="true"
          :current-node="treeNode"
          :label="treeNode.text"
          :nodes="treeNode.ContentItems"
          :list-logic="treeNode.ListLogic"
          :citations="treeNode.CitationLinks"
          :annotations="treeNode.AnnotationLinks"
          :match-paths="matchPaths"
          :highlight="treeNode.conditionsMet"
          :uid="treeNode.uid"
        />
        <TreeNode
          v-for="(notCovereditem, index) in notCoveredNode"
          :id="notCovereditem.id"
          :key="'notCoveredIndications' + index"
          :item="notCovereditem"
          :tree-root="true"
          :current-node="notCovereditem"
          :label="notCovereditem.text"
          :nodes="notCovereditem.ContentItems"
          :list-logic="notCovereditem.ListLogic"
          :citations="notCovereditem.CitationLinks"
          :annotations="notCovereditem.AnnotationLinks"
          :match-paths="matchPaths"
          :highlight="notCovereditem.conditionsMet"
          :uid="notCovereditem.uid"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import TreeNode from '@components/tree-node.vue'
import GuidelineTitle from '@components/guideline-title.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TreeWrapper',
  components: {
    TreeNode,
    GuidelineTitle,
  },
  props: {
    tree: {
      type: Array,
      default: () => [],
    },
    notCoveredNode: {
      type: Array,
      default: () => [],
    },
    matchPaths: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('guidelineData', [
      'guideline',
      'hasNotCovered',
      'notCoveredIndication',
      'guidelineMetStatuses',
    ]),
    ...mapGetters('guidelineSearch', ['searchData', 'selectedGuideline']),
    ...mapGetters('documentGuideline', [
      'documentedIndications',
      'documentedIndicationNotes',
    ]),
    hasGuideline() {
      return this.guideline.guideline != null
    },
    showGuidelineTree() {
      return this.guideline.guideline?.ContentItems?.length > 0
    },
  },
  mounted() {
    const selectedIndication = this.deepClone(
      this.documentedIndications.filter(
        (i) =>
          i.guidelineHsim === this?.guideline?.guidelineInfo?.hsim &&
          i.code !== this?.guideline?.guidelineInfo?.associatedCode
      )
    )
    if (selectedIndication) {
      if (this.documentedIndications && selectedIndication.length > 0) {
        selectedIndication.forEach((element) => {
          element.code = this?.guideline?.guidelineInfo?.associatedCode
          this.documentedIndications.push(element)
          const id =
            element.node.id !== undefined
              ? element.node.id + '-' + element.node.PathId
              : element.node.PathId
          this.setMetStatus({
            action: true,
            id: id,
          })
        })
      }
    }
    this.setRootNodes({
      code:
        this?.guideline?.guidelineInfo?.associatedCode ||
        this?.guideline?.guidelineInfo?.hsim,
      rootNodes: {
        ContentItems: [
          this?.guideline?.guideline?.ContentItems,
          this.notCoveredIndication,
        ],
      },
    })
  },
  methods: {
    ...mapActions('documentGuideline', ['setRootNodes']),
    ...mapActions('guidelineData', ['setMetStatus', 'setNotCovered']),
    nextStep() {
      this.$emit('next-step')
    },
    deepClone(source) {
      if (Array.isArray(source)) {
        return source.map((item) => this.deepClone(item))
      } else if (typeof source === 'object' && source !== null) {
        const clonedObj = {}
        for (const key in source) {
          if (Object.hasOwn(source, key)) {
            clonedObj[key] = this.deepClone(source[key])
          }
        }
        return clonedObj
      } else {
        return source
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@design';
/* stylelint-disable */
.noToggle {
  opacity: 0.5;
}

#guidelineTree {
  flex: 1;
  display: flex;
  flex-flow: column;

  .tree-wrapper {
    padding: 0 10px 0 0;

    > ul:first-child {
      > li:first-child:only-child::before {
        width: 0px;
      }
    }
    > .tree {
      padding: 0 0 10px 0;

      > li:first-child {
        margin: 0;
        border: none;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: calc(100% - 25px);
          background-color: #c1c5c7;
          width: 1px;
        }
        > .outer-node::before {
          display: none;
        }
      }
    }
  }
  .header {
    padding: 10px;
    display: flex;
    flex-flow: row nowrap;

    .guidelineTitle {
      font-size: 1.4rem;
    }

    .indicationMessage {
      font-size: 1.25rem;
      color: $highlight-color;
    }
  }
}
/* stylelint-enable */
</style>
