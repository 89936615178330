<template>
  <div>
    <div class="mask" @click.stop.prevent></div>
    <div data-cy="processing-spnner" class="popup">
      <div class="spinner">
        <RotateSquare2 />
      </div>
      <span data-cy="processing-message" class="message-span">
        {{ message }}
      </span>
    </div>
  </div>
</template>

<script>
import RotateSquare2 from '@components/rotate-square2.vue'

export default {
  name: 'Submitting',
  components: {
    RotateSquare2,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  margin: 0%;
  background: #fafafa;
  opacity: 0;
}

.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  width: 26%;
  min-width: 300px;
  height: 18%;
  min-height: 200px;
  margin: 12% 43% 65% 43%;
  font-weight: bold;
  background: #efefef;
  border: 3px solid #e0e3e7;
  border-radius: 25px;
  opacity: 1;
}

.spinner {
  margin: 0, 0, 90%;
}

.message-span {
  align-self: flex-end;
  margin: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  white-space: pre-wrap;
}
</style>
