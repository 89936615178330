<template>
  <div class="spinner-container">
    <div class="circle-container">
      <svg
        :style="styles"
        class="spinner spinner--circle"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          class="path"
          fill="none"
          stroke-width="6"
          stroke-linecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </div>
    <div class="message-container">
      <span>{{ message }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '30px',
    },
    message: {
      type: String,
      default: 'loading...',
    },
  },
  computed: {
    styles() {
      return {
        width: this.size,
        height: this.size,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$offset: 187;
$duration: 1.4s;
.spinner {
  animation: circle-rotator $duration linear infinite;
  * {
    box-sizing: border-box;
    line-height: 0;
  }
}
@keyframes circle-rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: circle-dash $duration ease-in-out infinite,
    circle-colors ($duration * 4) ease-in-out infinite;
}
@keyframes circle-colors {
  0% {
    stroke: #025893;
  }
  25% {
    stroke: #15958e;
  }
  50% {
    stroke: #025893;
  }
  75% {
    stroke: #15958e;
  }
  100% {
    stroke: #025893;
  }
}
@keyframes circle-dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset / 4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle-container {
  display: flex;
  justify-content: center;
}

.message-container {
  margin-bottom: 2em;
  text-align: center;
}
</style>
