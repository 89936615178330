<script>
import { mapGetters, mapActions } from 'vuex'

function hasMatch(item) {
  return item.matchingIndicationPaths?.length > 0
}

function hasMatches(array) {
  return array?.some((item) => hasMatch(item)) ?? false
}

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    resourceName: {
      type: String,
      default: null,
    },
    iconPath: {
      type: String,
      required: true,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    searchColumn: {
      type: String,
      required: true,
    },
    defaultSort: {
      type: Array,
      default: () => [],
    },
    alwaysEnabled: {
      type: Boolean,
      default: false,
    },
    openedDetailed: {
      type: Array,
      default: () => [],
    },
    printMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: false,
      filterQuery: '',
      matchesOnly: false,
    }
  },
  computed: {
    ...mapGetters('guidelineSearch', ['selectedGuideline']),
    ...mapGetters('fhirClinicalNotesBinary', ['sortOrder']),
    ...mapGetters('guidelineEvent', ['shouldExpandClinicalData']),
    hasData() {
      return this.tableData?.length > 0
    },
    hasMatches() {
      return hasMatches(this.tableData)
    },
    filteredData() {
      let filteredData = this.tableData
      if (this.matchesOnly) {
        filteredData = filteredData.filter((item) => hasMatch(item))
      }

      if (this.filterQuery) {
        const normalizedFilterQuery = this.filterQuery.toLowerCase()
        filteredData = filteredData.filter((item) =>
          item[this.searchColumn].toLowerCase().includes(normalizedFilterQuery)
        )
      }

      return filteredData ?? []
    },
    resourceTitle() {
      return this.resourceName?.toLowerCase() ?? this.title.toLowerCase()
    },
    slugifiedTitle() {
      return this.title.replace(' ', '-').toLowerCase()
    },
    hasDetails() {
      return this.$scopedSlots.detail != null
    },
    openedDetailRows: {
      get() {
        return this.openedDetailed || []
      },
      set() {},
    },
  },
  watch: {
    tableData: function (newData, oldData) {
      if (newData?.length === oldData?.length) {
        return
      }
      this.isExpanded =
        this.alwaysEnabled || hasMatches(this.tableData) || this.printMode
    },
    selectedGuideline: function () {
      this.filterQuery = ''
      this.matchesOnly = false
    },
    shouldExpandClinicalData(newValue, oldValue) {
      if (newValue) {
        this.isExpanded = this.hasData && this.hasMatches
      }
    },
  },
  methods: {
    ...mapActions('fhirClinicalNotesBinary', ['saveSortOrder']),
    toggleExpand() {
      if (this.hasData || this.alwaysEnabled) {
        this.isExpanded = !this.isExpanded

        this.$nextTick(() => {
          this.$el.scrollIntoView()
        })
      }
    },
    highlightRowClass(item) {
      return {
        'custom-selected': hasMatch(item),
      }
    },
    itemHasMatch(item) {
      return hasMatch(item)
    },
    saveSort(field, order) {
      if (this.sortOrder.field !== field || this.sortOrder.Order !== order) {
        this.saveSortOrder({ field, order })
      }
    },
  },
}
</script>

<template>
  <div v-if="hasData || !printMode" class="card card-custom is-fullwidth">
    <a
      :id="`${slugifiedTitle}`"
      :name="`${slugifiedTitle}`"
      :data-target-id="`${slugifiedTitle}`"
      :data-cy="`${slugifiedTitle}-card-header`"
      class="card-header no-select"
      :class="{
        'card-disabled': !hasData && !alwaysEnabled,
        'card-inactive': (hasData || !alwaysEnabled) && !isExpanded,
      }"
      @click="toggleExpand"
    >
      <p
        class="card-header-title card-title"
        :class="{ 'pdf-header': printMode }"
      >
        <img v-if="iconPath" :src="iconPath" :alt="`${title}-icon`" />
        {{ title }}
      </p>
      <span
        class="card-header-icon card-toggle"
        :class="{
          'is-hidden': !printMode && !hasData && !alwaysEnabled,
        }"
      >
        <span v-if="!printMode">
          <template v-if="isExpanded">
            <BaseIcon name="angle-down" />
          </template>
          <template v-else>
            <BaseIcon name="angle-up" />
          </template>
        </span>
      </span>
    </a>
    <div
      :data-cy="`${slugifiedTitle}-card-content`"
      :class="{ 'is-hidden': !isExpanded && !printMode }"
      class="card-content"
    >
      <slot name="content" />

      <div v-if="hasData" class="content">
        <div v-if="!printMode" class="filter">
          <input
            v-model="filterQuery"
            :placeholder="`Search by ${resourceTitle} name`"
            class="clinical-input-search"
            type="search"
          />
          <label v-if="hasMatches && !printMode" class="clinical-input-matches">
            matches only
            <input v-model="matchesOnly" type="checkbox" value="true" />
          </label>
        </div>

        <b-table
          :data="filteredData"
          narrowed
          striped
          hoverable
          :mobile-cards="false"
          :detailed="hasDetails"
          detail-key="id"
          custom-detail-row
          :show-detail-icon="false"
          :opened-detailed="openedDetailRows"
          :default-sort="defaultSort"
          sort-icon="angle-up"
          :row-class="
            (row, index) => ({
              'custom-selected': itemHasMatch(row),
              'hide-border': row.detailVisible,
              'pdf-body': printMode,
            })
          "
          @sort="saveSort"
          @details-open="(row) => (row.detailVisible = true)"
          @details-close="(row) => (row.detailVisible = false)"
        >
          <slot />
          <template v-slot:empty> No results found </template>
          <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
          </template>
        </b-table>
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<style lang="scss">
@import '@design';
.card-custom {
  border: 1px solid rgb(222, 226, 230);

  .table.is-striped tbody tr.custom-selected {
    color: $white;
    background-color: $highlight-color;
  }

  .table.is-striped tbody tr.custom-selected strong {
    font-weight: bold;
    color: $white;
    background-color: $highlight-color;
  }

  .table.is-hoverable.is-striped tbody tr.custom-selected:hover {
    color: $white;
    background-color: $highlight-hover-color;
  }

  .table.is-striped tbody tr.custom-selected td a {
    color: $white;
  }

  .card-content {
    font-size: 0.9rem;
  }

  img {
    margin-right: 0.5rem;
  }
}

.card-inactive {
  background-color: #efefef;
}

.card-disabled {
  color: #2c3e50;
  cursor: default;
  background-color: #e8e8eb;
  opacity: 0.5;
}

.card-title {
  font-size: 1.1rem;
  font-weight: 500;

  img {
    object-fit: contain;
    width: 30px;
    height: 30px;
  }
}

.filter {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 8px;

  .clinical-input-search {
    width: 225px;
    padding: 4px;
    font-size: 0.9rem;
    line-height: 1em;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    outline: none;
  }

  .clinical-input-matches {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.85;
  }
}
.sortable {
  position: relative;
  display: inline-block;
  padding-right: 15px;
  cursor: pointer;
  user-select: none;

  &::after,
  &::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 0;
    height: 0;
    content: ' ';
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    transform: translateY(-50%);
  }
  &.asc::after {
    border-bottom: 5px solid black;
  }
  &.desc::after {
    border-top: 5px solid black;
  }
}
.pdf-header {
  font-size: 1.6rem;
  font-weight: bold;
}
.pdf-body {
  font-size: 1.2rem;
}
</style>
