<template>
  <div v-if="!guidelineError && guideline.ready" id="reviewIndications">
    <div v-if="isCustomGuideline">
      <b-message data-cy="review-indications-disclaimer" type="is-info">
        Disclaimer: {{ disclaimer }}
      </b-message>
    </div>
    <TreeWrapper
      :key="componentKey"
      :tree="tree"
      :not-covered-node="notCoveredNode"
      :match-paths="matchPaths"
      :guideline-title="selectedGuideline.guidelineTitle"
      :glos="selectedGuideline.lengthOfStay"
      :has-matching-indications="hasMatchingIndications"
      @next-step="nextStep"
    />
  </div>

  <div v-else-if="guidelineError" class="tag-message-danger">
    <b-message
      data-cy="review-indications-error-message"
      :title="errorTitle"
      type="is-danger"
      :closable="false"
      icon="circle-exclamation"
      has-icon
    >
      <div v-for="line in errorMessage" :key="line">
        <span class="new-line">{{ line }}</span>
      </div>
    </b-message>
  </div>

  <div v-else class="spinner">
    <RotateSquare2 />
  </div>
</template>

<script>
import TreeWrapper from '@components/tree-wrapper.vue'
import RotateSquare2 from '@components/rotate-square2.vue'
import { mapActions, mapGetters } from 'vuex'
import { buildCompositeHsim } from '@utils/composite-hsim'
import { errorRedirectMixin } from '@mixins/error-redirect'

export default {
  name: 'ReviewIndications',
  components: {
    TreeWrapper,
    RotateSquare2,
  },
  mixins: [errorRedirectMixin],
  data() {
    return { componentKey: 0 }
  },
  computed: {
    ...mapGetters('guidelineData', [
      'guideline',
      'guidelineError',
      'notCoveredIndication',
    ]),
    ...mapGetters('guidelineSearch', ['searchData', 'selectedGuideline']),
    tree() {
      return this.guideline?.guideline.ContentItems
    },
    notCoveredNode() {
      return this.notCoveredIndication
    },
    matchPaths() {
      return this.$store.state.matchPaths // See IAD matchpaths in their mutations.js file
    },
    hasMatchingIndications() {
      return this.guideline?.matchedIndications?.length > 0
    },
    guidelineTitle() {
      return this.guideline?.outline?.guidelineTitle
    },
    disclaimer() {
      return this.guideline?.indications?.CustomGuidelineDisclaimer
    },
    isCustomGuideline() {
      return this.guideline?.indications?.CustomGuideline
    },
    errorTitle() {
      return (
        this.guidelineError?.Extensions?.errorCode ||
        'Guideline Could Not Be Loaded'
      )
    },
    errorMessage() {
      return (
        this.guidelineError?.Detail?.split('\\n') ||
        this.guidelineError?.split('\\n')
      )
    },
  },
  watch: {
    selectedGuideline: function (val) {
      if (!val) return

      this.setShouldExpandClinicalData(true)
      const hsim = buildCompositeHsim(val?.product, val?.hsim)
      if (
        this.guideline?.guidelineInfo?.hsim === hsim &&
        this.guideline?.guidelineInfo?.associatedCode === val.associatedCode
      ) {
        return
      }
      const selectedItem = this.searchData.find(
        (item) => item.searchOutput.guidelineCode === val.associatedCode
      )
      const selectedGuideline = selectedItem?.searchOutput.searchResults.find(
        (item) => item.hsim === val.hsim
      )
      const codeDescription =
        selectedItem?.searchOutput?.guidelineCodeDescription
      const contentEdition =
        selectedGuideline?.versionMajor + '.' + selectedGuideline?.versionMinor
      const guideline = {
        guidelineHsim: hsim,
        code: val.associatedCode || hsim,
        guidelineTitle: val.guidelineTitle,
        codeDescription,
        sortOrder: this.searchData.map(
          (item) => item.searchOutput.guidelineCode
        ),
        contentEdition,
      }

      this.addGuidelineToDocumentedList(guideline)

      this.retrieveGuideline({
        sessionId: this.$route.query.sessionId,
        selectedGuideline: val,
        searchData: this.searchData,
      })

      this.forceRerender()
    },
    guidelineError: function () {
      if (this.guidelineError) {
        this.validateError(
          this.guidelineError?.Extensions?.errorCode,
          this.guidelineError
        )
      }
    },
  },
  methods: {
    ...mapActions('guidelineData', ['retrieveGuideline']),
    ...mapActions('documentGuideline', ['addGuidelineToDocumentedList']),
    ...mapActions('guidelineEvent', ['setShouldExpandClinicalData']),
    nextStep() {
      this.$emit('next-step')
    },
    forceRerender() {
      this.componentKey++
      this.$nextTick(() => {
        this.setShouldExpandClinicalData(false)
      })
    },
  },
}
</script>

<style lang="scss">
/* stylelint-disable */
#reviewIndications {
  display: flex;
  flex: 1;
  flex-flow: column;
}
.tag-message-danger {
  border: 1px solid red;
  border-radius: 4px;
}
div .media-content {
  overflow-wrap: break-word;
}
.new-line {
  white-space: pre-line;
  color: #444;
}
/* stylelint-enable */
</style>
