<template>
  <div
    v-if="!guidelineError && tree"
    id="submitDocumentation"
    class="is-flex is-justify-content-space-between is-align-items-center"
  >
    <Processing v-if="isProcessing" :message="processingMessage"></Processing>
    <span></span>
    <span
      v-if="displayEpisodeId && authorizationData.EpisodeId"
      class="tag-message-container"
    >
      <b-tag
        class="media-content tag-message-success"
        aria-close-label="Dismiss upload error message"
      >
        <b-icon icon="circle-check" style="color: green"></b-icon>
        Successfully created review:&nbsp;<span
          data-cy="documentationEpisodeId"
        >
          {{ authorizationData.EpisodeId }}
        </span>
      </b-tag>
      <span style="visibility: hidden" data-cy="payorSiteUrl">
        {{ authorizationData.PayorSiteUrl }}</span
      >
    </span>
    <span v-else-if="authorizationData.ApiError" class="tag-message-container">
      <b-tag class="media-content tag-message-danger">
        <div style="float: left">
          <b-icon icon="circle-exclamation" style="color: red"></b-icon>
        </div>
        <div
          v-for="line in authorizationErrorDetail"
          :key="line"
          style="float: left; width: 95%; padding-left: 0.25rem"
        >
          <span class="new-line"> {{ line }}</span>
        </div>
      </b-tag>
    </span>
    <b-button
      id="btnSubmitAuthorization"
      :disabled="isDisabled"
      class="submit button is-primary"
      icon-right="forward-step"
      @click="submitAuthorization"
    >
      Continue
    </b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { errorRedirectMixin } from '@mixins/error-redirect'
import { SubmitStatus } from '@utils/submit-status'
import Processing from '@/src/components/processing.vue'
import axios from 'axios'
import {
  buildClinicalInput,
  guidelineCopyRightText,
  generateGuidelineSummary,
} from '@utils/document-guideline-util'

export default {
  name: 'SubmitDocumentation',
  components: {
    Processing,
  },
  mixins: [errorRedirectMixin],
  data() {
    return {
      renderPdf: false,
      isProcessing: false,
      processingMessage: '',
    }
  },
  computed: {
    ...mapGetters('authorization', [
      'authorizationData',
      'authorizationSubmitStatus',
      'authorizationError',
    ]),
    ...mapGetters('guidelineData', [
      'guideline',
      'guidelineError',
      'categorizedClinicalInput',
      'excludeFromAuthRequest',
      'clinicalInputs',
    ]),
    ...mapGetters('attachment', [
      'attachmentFiles',
      'hasFileUploadError',
      'uploadStatus',
    ]),
    ...mapGetters('clientConfig', ['clientConfig']),
    ...mapGetters('documentGuideline', [
      'documentedIndications',
      'documentedIndicationNotes',
      'episodeAdditionalNote',
      'documentedGuidelines',
      'previewDocumentedGuidelines',
    ]),
    tree() {
      return this.guideline?.guideline
    },
    isDisabled() {
      return (
        (this.authorizationSubmitStatus !== SubmitStatus.Error &&
          this.authorizationSubmitStatus !== SubmitStatus.NotSubmitted) ||
        this.uploadStatus === SubmitStatus.Processing
      )
    },
    displayEpisodeId() {
      return this.clientConfig.displayEpisodeId
    },
    episodeAdditionalNoteText() {
      return this.episodeAdditionalNote || ''
    },
    authorizationErrorDetail() {
      if (
        Object.keys(this.authorizationError).length > 0 ||
        this.authorizationData.ApiError?.message
      ) {
        return (
          this.authorizationError?.Detail?.split('\\n') ??
          this.authorizationError?.split('\\n') ??
          this.authorizationData.ApiError?.message?.split('\\n')
        )
      }
      return null
    },
  },
  watch: {
    authorizationError: function () {
      if (this.authorizationError) {
        this.validateError(
          this.authorizationError?.Extensions?.errorCode,
          this.authorizationError
        )
      }
    },
    authorizationData: function () {
      var targetOrigin = this.$route.query.refererUrl
      var notificationMode = this.$route.query.notificationMode
      var inIframe = window.self !== window.top

      if (
        this.authorizationData?.EpisodeId &&
        targetOrigin &&
        notificationMode === 'windowsPost' &&
        inIframe
      ) {
        var successPostMessage = {
          status: 'success',
          message: this.authorizationData.EpisodeId,
          detail: `Successfully created episode ${this.authorizationData.EpisodeId}`,
          attachments: this.attachmentFiles?.map((attachment) => ({
            name: attachment.name,
            id: attachment.externalAttachmentId,
          })),
        }

        window.parent.postMessage(
          JSON.stringify(successPostMessage),
          targetOrigin
        )
      }

      if (
        this.authorizationData?.EpisodeId &&
        notificationMode === 'callBackPost'
      ) {
        var callBackUrl
        var paramDelimiter = '?'

        if (targetOrigin.indexOf('?') > -1) {
          paramDelimiter = '&'
        }

        callBackUrl = `${targetOrigin}${paramDelimiter}episodeId=${this.authorizationData.EpisodeId}`

        axios.get(callBackUrl).catch((error) => {
          console.error('There was an error calling callBackUrl! ', error)
        })
      }
    },
    guidelineError: function () {
      if (this.guidelineError) {
        this.validateError(
          this.guidelineError?.Extensions?.errorCode,
          this.guidelineError
        )
      }
    },
  },
  methods: {
    ...mapActions('authorization', [
      'createAuthorization',
      'setAuthorizationSubmitStatus',
    ]),
    ...mapActions('documentGuideline', ['updatePreview']),
    submitAuthorization() {
      this.setAuthorizationSubmitStatus(SubmitStatus.Processing)
      this.updateProcessingMessage(0)
      this.enableInput(false)
      this.handleSubmit(this).then(() => {
        this.redirectPasAuthSummary()
        this.processingMessage = 'Done.'
      })
    },
    searchTree(node, pathId) {
      if (node.PathId === pathId) {
        return node
      } else if (node.ContentItems) {
        let result = null
        for (let i = 0; result == null && i < node.ContentItems.length; i++) {
          result = this.searchTree(node.ContentItems[i], pathId)
        }
        return result
      }

      return null
    },
    appendIndications(excludeData) {
      if (!excludeData) return

      excludeData.forEach((resource) => {
        if (resource.pathId) {
          resource.indication = []
          resource.pathId.forEach((pathId) => {
            const node = this.searchTree(this.tree, pathId)
            if (node) {
              const selected = this.documentedIndications.find(
                (item) => item.uid === node.uid
              )
              resource.indication.push({
                pathId,
                uid: node.uid,
                text: node.text,
                selected,
              })
            }
          })
        }
      })

      return excludeData
    },
    async handleSubmit() {
      const sessionId = this.$route.query.sessionId
      this.updateProcessingMessage(1)
      await this.createAuthorization({
        sessionId: sessionId,
        documentedGuidelines: this.getDocumentationModel(),
        attachments: this.attachmentFiles?.map((attachment) => ({
          ID: attachment.externalAttachmentId,
          FileName: attachment.name,
          ContentType: attachment.contentType,
          Title: attachment.title,
          Creation: attachment.creation,
          ByteSize: attachment.byteSize,
        })),
        episodeNote: this.htmlEntities(this.episodeAdditionalNoteText),
        clinicalData: this.categorizedClinicalInput,
        excludeFromAuthRequest: {
          data: this.appendIndications(this.excludeFromAuthRequest),
        },
      })

      this.enableInput(true)
    },

    htmlEntities(s) {
      return s.replace(/[\u00A0-\u9999<>\\&]/gim, function (i) {
        return '&#' + i.charCodeAt(0) + ';'
      })
    },

    getParent(node) {
      const path = node.PathId.split('-')
      path.pop()

      if (path.length > 0) {
        const parentNode = this.searchTree(this.tree, path.join('-'))
        return parentNode
      }
    },

    isExcluded(pathId) {
      return this.excludeFromAuthRequest?.find((x) =>
        x.pathId?.includes(pathId)
      )
    },

    hasNonDefinitionChildren(node) {
      if (!node) {
        return false
      }

      if (node.ContentItems) {
        return node.ContentItems.some((x) => !x.definitionId)
      }
    },

    eligibleForIndicationNote(node) {
      if (!node) {
        return false
      }

      const parentNode = this.getParent(node)
      if (!parentNode) {
        return false
      }

      if (!parentNode.definitionId && !this.hasNonDefinitionChildren(node)) {
        return true
      }
    },

    addClinicalDataNotes(notes, pathId, clinicalInput) {
      let node = this.searchTree(this.tree, pathId)

      while (node && !this.eligibleForIndicationNote(node)) {
        node = this.getParent(node)
      }

      const uid = node?.PathId.split('-').pop()
      const note = buildClinicalInput(clinicalInput, '  ')
      if (!notes.find((x) => x.node.uid === uid && x.note === note)) {
        notes.push({ node: { uid }, note })
      }
    },

    getDocumentationModel() {
      const documentedGuidelines = []
      this.updatePreview(this.clinicalInputs)
      this.documentedGuidelines?.forEach((guideline) => {
        let guidelinePreviewText = this.previewDocumentedGuidelines
          ?.find(
            (x) =>
              x.code === guideline?.code &&
              x.guidelineHsim === guideline.guidelineHsim
          )
          ?.allIndicationPaths?.map((node) =>
            this.generateGuidelineSummary(node)
          )
          .join('')
        if (guidelinePreviewText) {
          guidelinePreviewText += this.copyRightText(guideline.contentEdition)
        }

        const indications = this.documentedIndications?.filter(
          (x) =>
            x.code === guideline?.code &&
            x.guidelineHsim === guideline?.guidelineHsim
        )
        const notes =
          this.documentedIndicationNotes[guideline?.code]?.guidelineHsim ===
          guideline?.guidelineHsim
            ? this.documentedIndicationNotes[guideline?.code]?.nodes
            : []

        const matchingClinicalInputs = this.clinicalInputs?.filter(
          (input) => input.matchingIndicationPaths?.length > 0
        )

        matchingClinicalInputs?.forEach((input) => {
          input.matchingIndicationPaths?.forEach((path) => {
            if (!this.isExcluded(path)) {
              this.addClinicalDataNotes(notes, path, input)
            }
          })
        })

        const item = {
          contentVersion: guideline.contentEdition,
          hsim: guideline?.guidelineHsim,
          indications: indications.map((i) => i.node.uid),
          indicationNotes: notes?.map((n) => {
            return { IndicationUid: n.node.uid, Note: n.note }
          }),
          code: guideline?.code,
          guidelinePreviewText,
          guidelineTitle: guideline?.guidelineTitle,
          codeDescription: guideline?.codeDescription,
        }

        documentedGuidelines.push(item)
      })

      return documentedGuidelines
    },
    enableInput(state) {
      this.isProcessing = !state
      const tags = ['input', 'textarea', 'select']
      tags.forEach((tagName) => {
        const nodes = document.getElementsByTagName(tagName)
        for (let i = 0; i < nodes.length; i++) {
          nodes[i].disabled = !state
          nodes[i].tabIndex = -1
        }
      })
    },
    updateProcessingMessage(processingStep) {
      const messages = ['Submitting Documentation', 'Creating Authorization']
      let temp = '\n'

      for (let i = 0; i < messages.length; i++) {
        if (i !== 0 || this.attachmentFiles.length > 0) {
          const stepComplete = processingStep > i
          const stepCurrent = processingStep === i
          temp +=
            (stepComplete ? '☒ ' : '☐ ') +
            messages[i] +
            (stepCurrent ? '...\n' : '\n')
        }
      }

      this.processingMessage = temp
    },

    // Redirect to the Pas Auth Summary page if the UsePasFlow property is true.
    redirectPasAuthSummary() {
      if (this.authorizationData?.UsePasFlow === true) {
        this.$router.replace({
          path: '/pas-auth-summary',
          query: this.$route.query,
        })
      }
    },

    copyRightText(version) {
      return `<div class="copyrightText">${guidelineCopyRightText(
        version
      )}</div>`
    },
    generateGuidelineSummary(node) {
      return generateGuidelineSummary(node)
    },
  },
}
</script>

<style lang="scss" scoped>
/* stylelint-disable */
.submit {
  cursor: pointer;
}
.hide {
  display: none;
}

.tag-message-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-right: 0.75rem;

  .tag-message {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: top;
    align-items: center;
    height: auto !important;
    padding: 0.75em;
    font-size: 0.9rem;
    overflow-wrap: anywhere;
    white-space: break-spaces !important;
    ul {
      list-style: inside;
    }
  }
  .tag-message-success {
    /* Health/Green Light */
    background: #e6f8f6;
    /* Health/Green */
    border: 1px solid #00968f;
    border-radius: 4px;
    font-size: 0.9rem;
  }
  .tag-message-danger {
    background: #f7eaea;
    border: 1px solid #dc3200;
    border-radius: 4px;
    font-size: 0.9rem;
    height: auto !important;
  }
  .new-line {
    white-space: pre-line;
    color: #444;
  }
}
</style>
