<script>
import { mapGetters, mapActions } from 'vuex'
import appConfig from '@src/app.config'
import Layout from '@layouts/main.vue'
import ReviewIndications from '@components/review-indications.vue'
import ClinicalInput from '@components/clinical-input.vue'
import GuidelineList from '@components/guideline-list.vue'
import SubmitDocumentation from '@components/submit-documentation.vue'
import PreviewDocumentedGuidelines from '@/src/components/preview-documented-guidelines.vue'
import Help from '@components/help.vue'

export default {
  page: {
    title: 'Home',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    ClinicalInput,
    ReviewIndications,
    GuidelineList,
    SubmitDocumentation,
    PreviewDocumentedGuidelines,
    Help,
  },
  data() {
    const tabIndex = {
      GUIDELINE_LIST: 0,
      REVIEW_INDICATIONS: 1,
      PREVIEW: 2,
    }
    return {
      tabIndex,
      activeTab: tabIndex.GUIDELINE_LIST,
      helpVisible: false,
    }
  },
  computed: {
    ...mapGetters('guidelineSearch', ['selectedGuideline', 'searchData']),
    ...mapGetters('guidelineData', ['guideline', 'clinicalInputs']),
    ...mapGetters('documentGuideline', ['hasDocumentation']),
    hasSelectedGuideline() {
      return this.selectedGuideline && this.guideline?.guideline
    },
  },
  methods: {
    ...mapActions('guidelineData', ['addRequestInterrupt', 'setReady']),
    ...mapActions('guidelineSearch', ['selectGuideline']),
    ...mapActions('documentGuideline', ['updatePreview']),
    goToTab(index) {
      this.setReady(false)
      this.activeTab = index
    },
    cancelGuidelineRequest(index) {
      this.updatePreview(this.clinicalInputs)
      if (index === this.tabIndex.GUIDELINE_LIST) {
        this.addRequestInterrupt(this.selectedGuideline.hsim)
        if (!this.guideline?.guideline) {
          this.selectGuideline({ selectedGuideline: null })
        }
      }
    },
    allCodesHaveDocumentation() {
      const notDocumented = this.searchData.filter((row) => {
        return (
          row.searchOutput.searchResults.length > 0 &&
          !this.hasDocumentation(row)
        )
      })

      return notDocumented.length === 0
    },
    nextStep() {
      this.updatePreview(this.clinicalInputs)
      if (this.allCodesHaveDocumentation()) {
        this.goToTab(this.tabIndex.PREVIEW)
      } else {
        this.goToTab(this.tabIndex.GUIDELINE_LIST)
      }
    },
    openHelp() {
      this.helpVisible = true
    },
    closeHelp() {
      this.helpVisible = false
    },
  },
}
</script>

<template>
  <Layout @closeHelp="closeHelp">
    <div class="fixed-container">
      <div class="vertical-pane ml-1">
        <b-tabs
          v-model="activeTab"
          class="stretch-container stretch-item p-0"
          type="is-toggle"
          expanded
          @input="cancelGuidelineRequest"
        >
          <b-tab-item
            class="stretch-container stretch-item p-0 pt-2"
            label="Guideline List"
            icon="magnifying-glass"
          >
            <GuidelineList
              @guideline-select="() => goToTab(tabIndex.REVIEW_INDICATIONS)"
            />
          </b-tab-item>
          <b-tab-item
            class="stretch-container stretch-item p-0 pt-2"
            label="Review Indications"
            icon="book-medical"
            :disabled="!hasSelectedGuideline"
          >
            <ReviewIndications @next-step="nextStep" />
          </b-tab-item>
          <b-tab-item
            class="stretch-container stretch-item p-0 pt-2"
            label="Preview"
            icon="bars"
            :disabled="!hasSelectedGuideline"
          >
            <PreviewDocumentedGuidelines />
          </b-tab-item>
        </b-tabs>
      </div>
      <div class="vertical-pane mr-1">
        <div class="mb-2">
          <a>
            <img
              id="help-button"
              cy-data="help-button"
              class="help right"
              alt="Help"
              :src="require('@assets/images/icons/question.png')"
              @click="openHelp"
            />
          </a>
          <SubmitDocumentation />
        </div>
        <ClinicalInput />
        <Help v-if="helpVisible" @closeHelp="closeHelp" />
      </div>
    </div>
  </Layout>
</template>

<style lang="scss">
.help {
  float: right;
}
</style>
