<script>
import ClinicalInputCard from '@components/clinical-input-card.vue'
import { mapGetters, mapActions } from 'vuex'
import { SubmitStatus } from '@utils/submit-status'
import ErrorMessages from '@src/errorMessages'
import RotateCircle from '@components/rotate-circle.vue'

const bytesInOneMegabyte = 1048576
export const AttachmentSizeLimit = bytesInOneMegabyte * 25

export const FilenameLengthLimit = 100

export const ValidFileTypes = [
  'image/jpeg',
  'image/tiff',
  'image/bmp',
  'image/gif',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
]

export const ValidFileExtensions = [
  'jpg',
  'jpeg',
  'tif',
  'tiff',
  'gif',
  'bmp',
  'xls',
  'xlsx',
  'doc',
  'docx',
  'pdf',
]

export default {
  components: { ClinicalInputCard, RotateCircle },
  props: {
    printMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileSelectErrorMessage: null,
      isUploading: false,
      fileMessage: '',
    }
  },
  computed: {
    ...mapGetters('attachment', ['attachmentFiles', 'uploadStatus']),
    ...mapGetters('authorization', ['authorizationSubmitStatus']),
    ...mapGetters('guidelineSearch', ['searchData', 'guidelineSearchError']),
    ...mapGetters('guidelineData', ['guideline', 'guidelineError']),
    uploadEnabled() {
      return (
        (this.authorizationSubmitStatus === SubmitStatus.Error ||
          this.authorizationSubmitStatus === SubmitStatus.NotSubmitted) &&
        !this.isUploading
      )
    },
    panelEnabled() {
      return (
        !this.guidelineSearchError &&
        this.searchData?.length > 0 &&
        this.searchData?.reduce(
          (count, num) => count + num.searchOutput.searchResults?.length,
          0
        ) > 0 &&
        !this.guidelineError
      )
    },
    ValidFileTypes() {
      return ValidFileExtensions?.map((ext) => '.' + ext).join(',')
    },
  },
  methods: {
    ...mapActions('attachment', ['addAttachment', 'removeAttachment']),
    async add(file) {
      if (file == null || this.isUploading) {
        return
      }

      if (file.size > AttachmentSizeLimit) {
        this.fileSelectErrorMessage = ErrorMessages.AttachmentPanel.FileTooLarge
      } else if (file.name.length > FilenameLengthLimit) {
        this.fileSelectErrorMessage =
          ErrorMessages.AttachmentPanel.FilenameTooLong
      } else if (!this.isValidFileType(file)) {
        this.fileSelectErrorMessage =
          ErrorMessages.AttachmentPanel.InvalidFileExtension
      } else if (file.size <= 0) {
        this.fileSelectErrorMessage = ErrorMessages.AttachmentPanel.FileZeroSize
      } else {
        this.isUploading = true
        this.fileMessage = `Uploading: ${file.name}`
        const response = await this.addAttachment({
          sessionId: this.$route.query.sessionId,
          file,
        })
        if (response.hasUploadError) {
          this.isUploading = false
          this.fileSelectErrorMessage =
            ErrorMessages.AttachmentPanel.UploadError
        } else {
          this.fileSelectErrorMessage = null
          clearInterval(this.timer)
          this.$nextTick(() => {
            this.$refs.footer?.scrollIntoView({
              behavior: 'smooth',
            })
            this.isUploading = false
          })
        }
      }
    },
    isValidFileType(file) {
      return new RegExp(`(.*?)\\.(${ValidFileExtensions.join('|')})$`).test(
        file?.name
      )
    },
    fileHasError(file) {
      const uploadResponse = this.attachmentFiles.find(
        (a) => a.id === file.id
      )?.hasUploadError

      return uploadResponse
    },
  },
}
</script>

<template>
  <ClinicalInputCard
    title="Attachments"
    resource-name="attachment"
    :print-mode="printMode"
    :icon-path="require('@assets/images/icons/attachment_color_24dp.svg')"
    :table-data="attachmentFiles"
    search-column="name"
    :default-sort="['date', 'desc']"
    :always-enabled="panelEnabled"
  >
    <template v-slot:content>
      <div
        v-if="isUploading"
        class="container is-flex is-justify-content-center mt-2 mb-2 no-select"
      >
        <section class="section is-primary">
          <div class="content has-text-centered">
            <p>
              <RotateCircle :size="`40`" :message="fileMessage"></RotateCircle
            ></p>
          </div>
        </section>
      </div>
      <div
        v-if="fileSelectErrorMessage"
        data-cy="attachment-error-message"
        class="m-2 tag-message-container"
      >
        <b-tag
          class="tag-message"
          size="is-small"
          aria-close-label="Dismiss attachment error message"
          closable
          has-icon
          @close="fileSelectErrorMessage = null"
        >
          <div class="media-content">
            <b-icon icon="circle-exclamation" style="color: red"></b-icon>
            {{ fileSelectErrorMessage }}
          </div>
        </b-tag>
      </div>

      <div
        v-if="!printMode && !isUploading"
        class="container is-flex is-justify-content-center mt-2 mb-2 no-select"
      >
        <label class="upload control">
          <section class="section upload-draggable is-primary">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-medium" />
                <span>Click to Upload</span>
              </p>
              <p class="is-size-7"
                >Supported File Types:
                .jpg,.jpeg,.tiff,.gif,.bmp,.xls,.xlsx,.doc,.docx,.pdf
              </p>
            </div>
          </section>
          <input
            v-if="!printMode"
            ref="fileInput"
            type="file"
            :accept="ValidFileTypes"
            :disabled="!uploadEnabled"
            data-cy="attachment-input"
            @change="(event) => add(event.target.files[0])"
          />
        </label>
      </div>
    </template>

    <b-table-column
      v-slot="props"
      field="name"
      label="Attachment File Name"
      sortable
      cell-class="table-cell"
    >
      <div data-target-id="attachments" class="is-flex is-align-items-center">
        <b-icon class="mr-2" icon="paperclip" size="is-small" />
        <span
          :class="{
            'has-text-danger': fileHasError(props.row),
          }"
          >{{ props.row.name }}</span
        >
      </div>
    </b-table-column>

    <b-table-column
      v-if="!printMode"
      v-slot="props"
      label="Remove"
      cell-class="table-cell"
    >
      <div class="is-flex is-align-items-center">
        <b-button
          v-if="uploadEnabled"
          class="is-small"
          icon-right="trash"
          type="button"
          data-cy="remove-attachment"
          @click="removeAttachment({ id: props.row.id })"
        />
      </div>
    </b-table-column>

    <template v-slot:footer>
      <div ref="footer" />
    </template>
  </ClinicalInputCard>
</template>

<style lang="scss">
table {
  // This is required to allow <div>s in <td> elements to fill all of its available space
  height: 1px;

  tr {
    height: 100%;

    td.table-cell {
      height: 100%;
      word-break: break-all;
      overflow-wrap: anywhere;
      > * {
        height: 100%;
      }
    }
  }
}

.tag-message-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;

  .tag-message {
    height: auto !important;
    padding: 0.75em;
    font-size: 0.9rem;
    overflow-wrap: anywhere;
    white-space: break-spaces !important;
    background: #f7eaea;
    border: 1px solid #dc3200;
    border-radius: 4px;

    ul {
      list-style: inside;
    }
  }
}

.card .upload section {
  padding: 0.75rem;
}

.attach-gauge {
  display: block;
  width: 100%;
  margin: 2px;
  border: solid grey 1px;
}
</style>
