/* eslint-disable */
const formatIndicationText = (text = '', calculators = []) => {
  calculators.forEach((calcName) => {
    text = text.replace(new RegExp(`${calcName}(\\s+)Calculator`, 'i'), '')
  })
  text = text.trim().replace(/\[(.*?)\]]/g, '')
  return text
}

export default {
  methods: {
    formatIndicationText,
  },
}
